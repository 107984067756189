import { Location } from 'src/types/schema/graphql'
import { mapStore } from '../../state/mapStore'

const { setSelectedBreweryCoords, setSelectedBreweryRoutesTitle, setSelectedBreweryAddress } =
    mapStore.getState()
/**
 * handleCardHover()
 *
 * Set title and coordinates for a popup when a result card is hovered
 *
 */
const handleCardHover = (item: Location) => {
    if (item && item.address && item.address.geo && item.address.geo.lat && item.address.geo.lon) {
        const coords: [number, number] = [
            Number(item.address.geo.lon.toFixed(4)),
            Number(item.address.geo.lat.toFixed(4)),
        ]
        setSelectedBreweryCoords(coords)
        setSelectedBreweryAddress(
            `${item.address.street}, ${item.address.city}, ${item.address.state} ${item.address.postalCode}`
        )
    }
    if (item && item.brand) {
        if (item.brand.displayTitle) {
            setSelectedBreweryRoutesTitle(item.brand.displayTitle)
        } else if (item.brand.title) {
            setSelectedBreweryRoutesTitle(item.brand.title)
        } else {
            setSelectedBreweryRoutesTitle(item.title)
        }
    } else {
        setSelectedBreweryRoutesTitle(item && item.title)
    }
}
export { handleCardHover }
