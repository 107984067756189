import React from 'react'
import * as CC from '@chakra-ui/react'

import { FavoriteType } from 'src/features/favorites/types/enums/FavoriteType'
import { useFavoriteActionsStore } from 'src/features/favorites/state/useFavoriteActionsStore'
import { BoardItemType, Brand, CompositeProduct, Location } from 'src/types/schema/graphql'

import classNames from 'classnames'

interface IFavoritesMenu {
    favoriteType: FavoriteType
    item: Location | CompositeProduct | Brand
    editingIsEnabled: boolean
    note: string
}

const FavoriteCardFooter: React.FC<IFavoritesMenu> = ({
    favoriteType,
    item,
    editingIsEnabled,
    note,
}) => {
    const setActiveFavoriteItem = useFavoriteActionsStore((state) => state.setActiveFavoriteItem)
    const setActiveFavoriteType = useFavoriteActionsStore((state) => state.setActiveFavoriteType)
    const setFavoriteModalOpen = useFavoriteActionsStore((state) => state.setFavoriteModalOpen)
    const setActiveFavoriteAction = useFavoriteActionsStore(
        (state) => state.setActiveFavoriteAction
    )

    const handleClick = () => {
        if (!editingIsEnabled) return
        setActiveFavoriteAction('edit')
        setActiveFavoriteItem(item)
        setActiveFavoriteType(favoriteType as unknown as BoardItemType)
        setFavoriteModalOpen(true)
    }
    return (
        <CC.VStack
            className={classNames(
                'bg-gray-50',
                editingIsEnabled && 'cursor-pointer duration-150 ease-out hover:bg-gray-100'
            )}
            resize='none'
            fontSize={'sm'}
            onClick={handleClick}
            alignItems='flex-start'
            rounded='xl'
            p='4'
            w='full'
        >
            <p className='text-xs font-semibold text-gray-400'>
                {editingIsEnabled ? 'My Note' : 'Note'}
            </p>
            <div className='w-full'>{note}</div>
        </CC.VStack>
    )
}
export default FavoriteCardFooter
